@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body,
html {
  min-height: 100%;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

body.dx-color-scheme-light {
  background-color: #eee;
}
body.dx-color-scheme-dark {
  background-color: #2a2a2a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-container {
  position: relative;
  min-height: 100vh;
}

.page-content {
  padding-bottom: 3.5rem;
}
